@import "../clarity-colors";

//== Typography
// ----------------------------------------------------------------------------
//## Common styles for text, inputs and more

//** Background color for `<body>`.
$body-bg: #fff !default;

$center-pane-color: $clr-almost-white;
$white: #ffffff;
$green-opacity-7: rgba(183, 229, 153, 0.7);
$green-opacity-4: rgba(183, 229, 153, 0.4);

$tree-item-selected: #D9E4EA;
$tree-item-hover: $clr-near-white;

// Non-standard colors ...
$portlet-border: 1px solid #d8d8d8;
$resource-bar-color: #87b0de;

$vui-background-color: $white;
$vui-text-color: $clr-near-black;

// Grey menu
$grey-menu-background: $clr-near-white;
$grey-menu-background-selected: $clr-near-white;
$menu-font-color: $clr-gray;


$clr-row-hover-color: $clr-near-white;
$clr-row-selection-color: $clr-near-white;
$clr-row-hover-text-color: $clr-gray;
$clr-title-color: $dark-grey-10;

$panel-border: 1px solid #ebeff1;